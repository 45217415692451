<template>
    <v-card outlined>
      <v-card-text>
        <v-data-table
          flat 
          disable-sort
          class="mb-3 title" 
          hide-default-footer
          loader-height="2"
          :headers="headers"
          :loading="loading"
          :items="configurations.data.content">
          <template v-slot:item.edit_action="{ item }">
                <v-btn
                dark
                label
                small
                color="primary"
                class="caption ttn"
                @click="$emit('edit', item)"
                >
                Edit
                </v-btn>
          </template>
        </v-data-table>
        <app-pagination
          v-if="configurations.meta"
          :meta="configurations.meta"
          @pageChanged="pageChanged"
        ></app-pagination>

      </v-card-text>
    </v-card>
  
  </template>
  
  <script>
  import { mapActions, mapGetters } from 'vuex'
  export default {
    components: {
      'configuration-form': () => import('@/components/paybill/ConfigurationForm.vue'),
    },

    data () {
      return {
        page: 1,
        config_dialog: null,
        headers: [
          { text: 'Paybill Number', value: 'paybillNo' },
          { text: 'Paybill Name', value: 'organisationName' },
          { text: 'Action', value: 'edit_action' },
        ],
      }
    },
  
    computed: {
      ...mapGetters({
        configurations: 'getConfigurations'
      })
    },
  
    methods: {
      ...mapActions([
        'setConfigurations'
      ]),
  
      pageChanged (page) {
        this.page = page
        this.setConfigurations()
      },
    },
  
    mounted () {
      this.setConfigurations().then(() => {
        this.loading = false
      })
    }
  }
  </script>